.main_container {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 150px
}

.date_container {
    width: 60%;
    margin-left: 20%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 15px;
}

.button_container {
    width: 60%;
    margin-left: 20%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
}