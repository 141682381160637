.background {
    position: fixed;
    z-index: 11;
    background-color: rgba(0, 0, 0, 0.75);
    width: 800vw;
    height: 1000vh;
    top: 0;
    left: 0;
  }
  
  .modal {
    box-shadow: 0 1px 4px hsla(0, 0%, 0%, 0.2);
    border-radius: 6px;
    background-color: hsl(0, 0%, 100%);
    padding: 1.2rem;
    text-align: center;
    width: var(--width);
    position: fixed;
    z-index: 20;
    top: var(--top);
    left: calc(50% - var(--width) / 2);
    height: var(--height);
    display: flex;
    align-items: center;
  }
  
  .viddo_modal {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background-color: rgb(255, 255, 255);
    padding: 1.2rem;
    text-align: center;
    width: 50rem;
    position: fixed;
    z-index: 12;
    top: 20vh;
    left: calc(50% - 25rem);
  }
  
  .modal p {
    text-align: justify;
  }
  
  .btn_main {
    font: inherit;
    margin-top: 0.5rem;
    padding: 0.5rem 1.5rem;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    background-color: rgba(6, 3, 141, 0.95);
    color: rgb(255, 255, 255);
    border: 1px solid rgba(6, 3, 141, 0.95);
  }
  
  .btn_main:hover {
    background-color: rgba(6, 3, 141, 0.85);
    border-color: rgba(6, 3, 141, 0.85);
  }
  