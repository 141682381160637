.pagebutton {
  font: inherit;
  cursor: pointer;
  background-color: rgb(0,94,184);
  color: rgb(255, 255, 255);
  padding: 0.5rem 1.5rem;
  border: 2.5px solid rgb(0,94,184);
  border-radius: 4px;
  font-size: 1.2rem;
  font-weight: bold;
}

.pagebutton:hover {
  color: rgb(255, 255, 255);
  border: 2.5px solid rgba(0,94,184, 0.65);
  background-color: rgba(0,94,184, 0.65);
}
