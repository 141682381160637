.toggle-container-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    position: relative;
  
    }
    
    .toggle-container-container span {
      top: -55%;
      left: 130px;
      position: absolute;
      /* left: -120%; */
      font-size: 0.6em;
      color: rgb(200,200,200);
      transition: color 0.4s linear;
    }
  
    .toggle-container-container span.activated {
      color: black;
    }
    .toggle-container {
      position: relative;
      aspect-ratio: 292/142;
      height: 1.2em;
    }
  
    
    .toggle-input {
      appearance: none;
      margin: 0;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
    
    .toggle {
      width: 100%;
      height: 100%;
      overflow: visible;
    }
    
    .toggle-background {
      fill: var(--inactive-color);
      transition: fill 0.4s;
    }
    .toggle-input:checked + .toggle .toggle-background {
      fill: var(--active-color);
    }
    
    .toggle-circle-center {
      transform-origin: center;
      transition: transform 0.6s;
    }
    .toggle-input:checked + .toggle .toggle-circle-center {
      transform: translateX(150px);
    }
    
    .toggle-circle {
      transform-origin: center;
      backface-visibility: hidden;
      transition: transform 0.45s;
    }
    .toggle-circle.left {
      transform: scale(1);
    }
    .toggle-input:checked + .toggle .toggle-circle.left {
      transform: scale(0);
    }
    .toggle-circle.right {
      transform: scale(0);
    }
    .toggle-input:checked + .toggle .toggle-circle.right {
      transform: scale(1);
    }
    
    .toggle-icon {
      transition: fill 0.4s;
    }
    .toggle-icon.on {
      fill: var(--border-default);
    }
    .toggle-input:checked + .toggle .toggle-icon.on {
      fill: #fff;
    }
    .toggle-icon.off {
      fill: #eaeaec;
    }
    .toggle-input:checked + .toggle .toggle-icon.off {
      fill: var(--background-selected);
    }
  
    .toggle-container p {
      opacity: 0;
      pointer-events: none;
      position: absolute;
      width: 242%;
      height: 100px;
      left: -70%;
      top: 0;
      background: white;
      font-size: 1.25rem;
      text-align: center;
      border: 2px solid black;
      border-radius: 10px
    }

    .disabled .toggle-input:hover {
      cursor: not-allowed
    }

    .disabled.toggle-container:hover p {
      opacity: 1;
    }