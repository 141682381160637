.radio_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .radio_container input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    width: 50%;
    height: calc(1.5rem - 4px);
    border: 2px solid black;
    overflow: hidden; 
    border-radius: 4px;
    outline: none;
    margin: 0;
    margin-right: 0.4rem;
    cursor: pointer;
  }
  
  .radio_container input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 0.5rem;
    height: 1.5rem;
    box-shadow: -500px 0 0 500px hsla(174, 75%, 36%, 0.5);
    background: rgba(0, 0, 0, 1);
    opacity: 1;
  }
  
  input[type="range"]:hover::-webkit-slider-thumb {
    opacity: 1;
    box-shadow: -500px 0 0 500px hsla(174, 75%, 36%, 0.8);
  }
  
  
  .radio_container p:not(.immediate_ret), .radio_container input[type="text"] {
    display: inline-block;
    color: rgba(0, 0, 0, 1);
    border: 2px solid rgba(0, 0, 0, 1);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 4px;
    font-weight: 600;
    flex: 3;
    text-align: center;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(1.5rem - 4px);
    margin: 0;
    width: 40%;
    padding: 0;
    transition: transform 0.2s linear
  }
  
  .radio_container input[type="text"]:focus {
    transform: scale(1.07);
    outline: none;
  }

  .main_container h2 {
    margin: 0;
    margin-bottom: 5px;
    text-align:center;
  }

  .max_min_labels {
    width: 50%;
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  .max_min_labels .max {
    position: absolute;
    right: 10px;
    width: 20px;
    text-align: center;
    padding-top: 5px;
    font-weight: bold
  }

  .max_min_labels .min {
    position: absolute;
    left: -5px;
    width: 20px;
    text-align: center;
    padding-top: 5px;
    font-weight: bold
  }