.main_container {
    border-radius: 6px;
    border: 1.5px solid rgb(0, 0, 0);
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    transition: all 0.5s linear;
    width: 100%; 
    height: 20vh;
    margin-right: 1%;
    position: relative;
    flex-grow: 1
}
  
.main_container.invisible_container {
width: 0;
overflow: hidden;
margin: 0;
padding: 0;
opacity: 0;
margin-right: 0 !important
}


.header {
display: flex;
flex-direction: row;
justify-content: space-between;
margin-right: 0 !important;
}

.help_button {
display: block;
border: 2px solid rgba(0, 0, 0, 1);
color: rgba(0, 0, 0, 1);
border-radius: 6px;
background-color: transparent;
font-size: 0.8rem;
font-weight: 600;
height: fit-content;
position: absolute;
right: 15px
}

.help_button:hover {
cursor: pointer;
border: 2px solid hsl(0, 0%, 0%, 0.6);
color: hsl(0, 0%, 0%, 0.6);
}


.radio_container {
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 30px;
}


.radio_container p:not(.immediate_ret), .radio_container input[type="text"] {
    display: inline-block;
    color: rgba(0, 0, 0, 1);
    border: 2px solid rgba(0, 0, 0, 1);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 4px;
    font-weight: 600;
    flex: 3;
    text-align: center;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.07rem;
    width: 20%;
    padding: 0;
    transition: transform 0.2s linear
  }
  
  .radio_container input[type="text"]:focus {
    transform: scale(1.07);
    outline: none;
  }

  .ret_age_button, .adjust_button {
    display: inline-block;
    padding: 0.25rem;
    color: hsl(0, 0%, 66%);
    border: 2px solid hsl(0, 0%, 66%);
    background-color: hsla(0, 0%, 66%, 0.2);
    border-radius: 4px;
    font-weight: 600;
    flex: 1 1;
    text-align: center;
    font-size: 1.2rem;
  }
  
  .ret_age_button:hover, .adjust_button:hover {
    color: hsl(205, 58%, 59%);
      border-color: hsl(205, 58%, 59%);
      text-decoration: none;
      background-color: hsla(205, 58%, 59%, 0.2);
      cursor: pointer;
  }
  
  .adjust_button.greyed_out:hover {
    cursor: not-allowed;
  
  }

  .ret_age_button.selected {
    background-color: hsla(174, 75%, 36%, 0.2);
    border: 2px solid hsl(174, 75%, 36%);
    color: hsl(174, 75%, 36%);
  }
  
  .adjust_button.active_button {
    background-color: hsla(174, 75%, 36%, 0.2);
    border: 2px solid hsl(174, 75%, 36%);
    color: hsl(174, 75%, 36%);
  }

  .main_container button, .main_container h3 {
    font-size: 1.2rem !important
  }

  .main_container {
    position: relative
  }
  
  .immediate_ret {
    color: black;
    position: absolute;
    top: 115%
    
  }
  
  .extra_container {
    position: relative
  }
  
  .ret_age_container {
    margin-right: 1%
  }
  
  .reset_container {
    width:100%;
    display:flex;
    justify-content: center;
    margin-bottom: 10px
  }
  
  .warning_message {
    color: black;
    position: absolute;
    border: 2px solid black;
    background: crimson;
    top: 5%;
    left: calc(25% - 22px);
    width: 50%;
    padding: 20px;
    border-radius: 4px;
    animation: appear 0.3s ease-in
}

@keyframes appear {
  0% {opacity: 0}
  100% {opacity: 100%}
}

.npa_buttons_container {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: 10px;
  }

.npa_buttons_container button:first-child {
  margin-right: 5px
}

.npa_buttons_container button:last-child {
  margin-left: 5px
}

.radio_container input {
  margin-left: 5px; 
  margin-right: 5px
}

.everything_except_title_container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}