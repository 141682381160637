.show_as_container {
    border-radius: 6px;
    border: 1.5px solid rgb(0, 0, 0);
    padding: 10px 15px;
    transition: all 0.5s linear;
    width: 100%;
    margin-right: 1%;
    margin-top: 10px
}

.show_as_container h2 {
    text-align: center
}
.show_as_container > div:first-of-type {
    display: flex; 
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.show_as_container button {
    display: inline-block;
    padding: 0.3rem;
    color: hsl(0, 0%, 66%);
    border: 2px solid hsl(0, 0%, 66%);
    background-color: hsla(0, 0%, 66%, 0.2);
    border-radius: 4px;
    font-weight: 600;
    flex: 1 1;
    text-align: center;
    font-size: 1.2rem;
    width: 80%;
    flex: 0.4
}

.show_as_container button:hover {
    color: hsl(205, 58%, 59%);
    border-color: hsl(205, 58%, 59%);
    text-decoration: none;
    background-color: hsla(205, 58%, 59%, 0.2);
    cursor: pointer;
}

.show_as_container button.selected {
    color: var(--background-selected);
    border-color: var(--background-selected);
    background-color: var(--border-selected)
}

.fancy_toggle_container {
    flex: 0.3;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 15px;
    width: 80%;
    margin-left: 10%;
}

.show_as_container .fancy_toggle_container p {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600
}

.more_options {
    margin-bottom:0;
    font-weight: 600;
    margin-top: 20px;
    text-align: center
}