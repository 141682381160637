.headerContainer {
  background-color: rgb(0,94,184);
  border-bottom: 5px solid rgb(0,48,125);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: 0 2px 8px hsla(0, 0%, 0%, 0.25);
  z-index: 4;
  padding: 0;
  height: fit-content;
  padding-top: 0.25rem;
}

.mainHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  width: 60%;
  min-width: 1128;
  max-width: 1400px;
}

@media (max-width: 1880px) {
  .mainHeader {
    flex-direction: column;
    justify-content: center;
  }
  .mainHeader h1 {
    text-align: center;
  }
}

@media (max-width: 1000px) {
  .mainHeader h1 {
    font-size: 2rem !important;
  }
}

.mainHeader h1 {
  color: white;
  font-weight: 700;
  font-size: 2.35rem;
  margin: 0;
}

.imgContainer {
  width: 5.5rem;
}

img {
  height: 60px;
}
