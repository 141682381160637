.minorButton {
  font: inherit;
  cursor: pointer;
  background-color: hsl(220, 100%, 25%);
  color: white;
  padding: 0.5rem 1.5rem;
  border: 1px solid hsl(220, 100%, 25%);
  border-radius: 4px;
  font-weight: bold;
  margin: 0;
  font-size: 1.25rem;
}

.minorButton:hover,
.minorButton:active {
  background-color: hsl(220, 100%, 30%);
  border-color: hsl(220, 100%, 30%);
}
