:focus{outline: none;}

/* necessary to give position: relative to parent. */
.effect-19{
     color: var(--text-color); 
     width: 100%; box-sizing: 
     border-box; 
     letter-spacing: 1px;
    }

:focus{outline: none;}

.col-3{ width: var(--box-width); position: relative;} /* necessary to give position: relative to parent. */
.col-3 input[type="text"]{ width: 100%; box-sizing: border-box; letter-spacing: 1px}

.effect-19{border: 1px solid black; padding: 7px 14px; transition: 0.8s; background: white;}

.effect-19 ~ .focus-border:before,
.effect-19 ~ .focus-border:after{
    content: ""; 
    position: absolute;
     top: -1px; 
     left: 50%; 
     width: 0;
      height: 2px; 
      background-color: var(--border-color);
       transition: 0.8s;}
.effect-19 ~ .focus-border:after{top: auto; bottom: 0;}
.effect-19 ~ .focus-border i:before,
.effect-19 ~ .focus-border i:after{
    content: ""; 
    position: absolute;
     top: 50%; 
     left: 0; 
     width: 2px;
      height: 0;
       background-color: var(--border-color); 
       transition: 1.2s;
    }
.effect-19 ~ .focus-border i:after{left: auto; right: 0;}
.effect-19:focus ~ .focus-border:before,
.effect-19:focus ~ .focus-border:after,
.has-content.effect-19 ~ .focus-border:before,
.has-content.effect-19 ~ .focus-border:after{left: 0; width: 100%; transition: 0.8s;}
.effect-19:focus ~ .focus-border i:before,
.effect-19:focus ~ .focus-border i:after,
.has-content.effect-19 ~ .focus-border i:before,
.has-content.effect-19 ~ .focus-border i:after{top: -1px; height: 100%; transition: 1.2s;}
.effect-19 ~ label{
    margin-left: var(--left-margin) ; 
    position: absolute;
    left: 0%;
    width: 100%;
    top: 20%;
    color: var(--text-color);
    transition: 0.6s;
    z-index: 1;
    letter-spacing: 0.5px;
    pointer-events: none;
    font-size: 1.25rem;
    }
.effect-19:focus ~ label, .has-content.effect-19 ~ label{top: -18px; left: 0; font-size: 12px; color: var(--text-color); transition: 0.6s;}

.mahi_holder {
    width: 100%;
    display: var(--centralise);
    justify-content: center;
}

.mahi_holder.col-3 Input {
    margin-top : 0;
    height: 30px;
}

.col-3 {
    margin-top: 30px
}