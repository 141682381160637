.main_container {
    width: 80%;
    margin-left: 10%;
    height: 200px;
    padding-top: 10px;
    transition: all 0.3s cubic-bezier(0.23, 1, 0.320, 1)
}


.main_container.invisible {
    height: 0;
    overflow: hidden;
    margin: 0;
    opacity: 0;
    padding: 0
}

.sub_container {
    display: flex;
    width: 80%;
    margin-left: 10%;
    align-items: center;
    justify-content: center;
    height: 170px;
    margin-top: 15px;
    margin-bottom: 15px;
    border: 2px solid red;
    transition: all 0.2s linear
}

.sub_container:hover {
    transform: scale(1.05);
    cursor: pointer;
    background: lightcoral
}


.sub_container img {
    height: 3.5vh
}

.img_container {
    width: 20%;
    text-align: center
}

.sub_container p {
    text-align: center;
    width: calc(80% - 20px);
    height: calc(9vh - 20px);
    padding: 10px 10px;
    font-size: 1rem;
    display: flex;
    align-items: center
}