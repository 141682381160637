.input_container {
  background-color: rgb(242, 242, 242);
  border-radius: 6px;
  padding: 20px 18px;
  margin-top: 0.3rem;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  max-height: 300px;
  transition: all 0.4s linear;
}

.cookie_container {
  background-color: white;
  border: 1px solid black;
  border-radius: 6px;
  padding: 20px 18px;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  margin-bottom: 2rem;
  max-height: 300px;
  transition: all 0.4s linear;
  display: flex; 
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 5px 5px rgba(0,94,184, 0.2)
}

.cookie_container p {
  font-size: 1.25rem
}

.button_container {
  margin: 0.5rem auto;
  width: calc(80% + 36px);
  display: flex;
  justify-content: flex-end;
}


.yourDetails {
  overflow: hidden;
  margin-bottom: 100px;
}

@media screen and (min-width: 600px) and (max-width: 900px) {
  .yourDetails {
    min-height: calc(var(--mh) * 1.3)
  }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .yourDetails {
    min-height: calc(var(--mh) * 1.15)
  }
}

@media screen and (min-width: 1200px) {
  .yourDetails {
    min-height: calc(var(--mh) * 0.95)
  }
}

.service_too_low {
  width: 80%;
  margin: 0.3rem auto;
  border: 2px solid red;
  padding: 10px;
  border-radius: 10px;
  font-size: 18px;
  color: red;
}