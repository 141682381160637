

.reults_table h2, .results_table p {
    font-size: 20px;
    font-weight: 700
}

.results_table h1 {
    font-size: 25px;
}
.boxes_container {
    display: grid;
    grid-template-rows: 0.5fr 0.5fr 1fr 1fr; /* Four rows */
    grid-template-columns: 46% 4% 46%; /* Column sizes */
    gap: 2%; /* Optional: adds some space between the grid items */
    width: 96%;
    height: 60vh;
    background: rgba(200,200,200,0.3);
    box-shadow: 0px 0px 2px 2px grey;
    border-radius: 10px;
    padding: 2%
  }
  


.box {
    opacity: 1;
    color: white;
    position: relative;
    border-radius: 10px;
}

.box:first-child {
    grid-column: 1 / -1; /* This will make the first box span all columns */
    background: rgb(0,94,184)
}

.box:nth-child(2) {
    background: rgb(37, 48, 55)
}

.box:nth-child(5), .box:nth-child(8) {
    background: var(--background-selected)
}

.box:nth-child(4) {
    background: rgb(47, 106, 148)
}

.box:nth-child(7), .box:nth-child(10) {
    background: hsla(205, 58%, 59%)
}

.box:nth-child(2), .box:nth-child(4) {
    display: flex;
    justify-content: center;
    align-items: center;
}

.box h1 {
    height: 100%;
    position: absolute;
    top: 0
}

.box h2 {
    height: 20%;
    font-size: 20px
}

.box h1, .box h2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0
}

.box:first-child h2 {
    margin-top: 1%;
    margin-bottom: 1%
}

.based_on {
    height: 50%;
    margin-bottom: 3%
}


.based_on {
    display: flex;
    justify-content: space-around
}

.based_on > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(183, 226, 227, 0.3);
    padding: 10px;
    border-radius: 10px
}

.choose_option {
    padding: 20px;
    text-align: center;
}