.retirement_age_warning {
    width: 20%;
    border: 1.5px solid black;
    border-radius: 4px;
    transition: all 0.6s linear;
    max-height: 1000px;
    display: flex;
    flex-direction: column;
    margin-right: 1.5px;
    background: rgb(240, 240, 240);
    
}

.retirement_age_warning img {
    width: 40px;
    height: 30px
}

.retirement_age_warning h2 {
    height: 10%;
    margin-top: 0.75rem;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    width: calc(100% - 20px)
}

.retirement_age_warning > div {
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
    flex-grow:1
}
.image_container {
    margin-left: 5%;
    display: flex;
    align-items: center;
    margin-top:5px;
    margin-bottom: 5px;
    flex-grow: 1;
}

.image_container button {
    margin-left: 1%;
    text-align: center;
    width: calc(90% - 30px);
    margin-left: 3%;
    padding: 2%;
    background: white;
    color: black;
    border: None;
    font-weight: 700;
    transition: transform 0.1s linear;
    font-size: 0.8rem;
    position: relative;
    box-shadow: 0 0 1px 1px lightgrey
}

.image_container button:hover {
    cursor: pointer;
    background: var(--col2);
    transform: scale(1.05)
}

.line {
    position: absolute;
    top:-2px;
    width: calc(100%);
    background: var(--col1);
    height: 2px;
    right: 0px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.bullets {
    text-align: left
}

.bullets li {
    margin-bottom: 1em
}

.warnings_container {
    display: flex;
    flex-direction: column;
}

.warnings_container > div {
    width: 0%
}

.warnings_container > div.visible {
    width: 100%
}

.first_warning, .second_warning, .third_warning, .fourth_warning {
    opacity: 0;
    transition: all 0.3s linear;
    max-height: 0px;
    transform: scaleY(0)
}

.first_warning.visible, .second_warning.visible, .third_warning.visible, .fourth_warning.visible {
    opacity: 1;
    transform: scaleY(1);
    max-height: 130px;
    flex-grow: 1;

}