.legend {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.legend_circle {
    width: 20px;
    max-width: 20px;
    min-width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 30px
}

.legend_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px
}

.chart_and_legend_container {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid grey;
    box-shadow: 0px 0px 2px 2px lightgrey;
}

.chart_container {
    width: 58%;
    height: 250px;
    padding-top: 20px;
}

.legend_container {
    width: 40%;
    padding-top: 20px;
    height: 250px;
    padding-left: 1%;
    padding-right: 1%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.card_container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 0px;
    width: 60%
    
}

.card_and_explanation_container {
    border-radius: 10px;
    border: 1px solid black;
    margin-bottom : 10px;
    padding : 10px;
    display: flex;
    width: 100%;
    align-items: center;
}

.explanation {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.main_container h1 {
    margin-top : 0.5rem
}

.btn_container {
    width : 100%;
    display: flex; 
    justify-content: space-around;
    margin-top: 10px;
}

.salary_slider_container {
    width: 80%;
    margin-left: 10%;
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.explanation_inner {
   margin-left: 10px;
}

.explanation_inner p {
    text-align: center
}

.btn_container button {
    width: 400px
}