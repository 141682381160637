.container {
  margin-top: 1rem;
}

.comp_table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  padding: 0;
  overflow: visible;
  border-radius: 6px;
  border: none;
  margin-bottom: 30px;
  font-size: 1.25rem
}

.comp_table thead,
.tabcomp_tablele tbody,
.comp_table td {
  border: none;
  text-align: center;
}

/* .comp_table td:nth-of-type(3n + 0) {
  border-right: 1px solid black;
} */

.comp_table thead {
  /* border: 1px solid black;
  border-radius: 6px; */
  background-color: rgb(0,94,184);
  font-weight: bold;
  color: rgb(255, 255, 255);
}

.comp_table tbody tr:nth-child(even) {
  background-color: rgba(168, 168, 168, 0.2);
}

.lump_sum_td {
  position: relative;
}

.lump_sum_td button {
  position: absolute;
  z-index: 10;
  right: -73px;
  bottom: -12.5px;
  border: 2px solid rgba(0, 0, 0, 1);
  color: rgba(0, 0, 0, 1);
  border-radius: 6px;
  background-color: transparent;
  font-size: 1.25rem;
  font-weight: 600;
  height: 25px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lump_sum_td button:hover {
  cursor: pointer;
  border: 2px solid hsl(0, 0%, 0%, 0.6);
  color: hsl(0, 0%, 0%, 0.6);
}

.extra_row {
  max-height: 1000px;
  animation: grow 0.3s linear;
}

@keyframes grow {
  0% {transform: scaleY(0)}
}

.comp_table tr:nth-child(odd):not(:first-child) {
  border-top: 1.5px solid black
}

.comp_table tr:nth-child(1), .comp_table tr:nth-child(2) {
  font-weight: 600
}

.comp_table td {
  font-size: 1.25rem
}
/* 
.comp_table tr:nth-child(odd) {
  padding: 1rem;
  background: #f2f2f2;
  border: 2px solid white;
  border-radius: 6px;
} */
