.button {
    display: inline-block;
    padding: 0.25rem;
    color: hsl(0, 0%, 66%);
    border: 2px solid hsl(0, 0%, 66%);
    background-color: hsla(0, 0%, 66%, 0.2);
    border-radius: 4px;
    font-weight: 600;
    text-align: center;
    font-size: 1.2rem;
    width: 300px;
  }
  
  .button:hover {
    color: hsl(205, 58%, 59%);
      border-color: hsl(205, 58%, 59%);
      text-decoration: none;
      background-color: hsla(205, 58%, 59%, 0.2);
      cursor: pointer;
  }

.button.active {
  background-color: var(--active-background);
  color: var(--active-border);
  border-color: var(--active-border)
}

.button.active:hover {
  background-color: var(--active-background);
  color: var(--active-border);
  border-color: var(--active-border)
}