.radio_container {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    justify-content: space-around;
  }

  .radio_container input[type="range"] {
    appearance: none;
    height: calc(1.5rem - 4px);
    border: 2px solid black;
    overflow: hidden;
    border-radius: 4px;
    outline: none;
    margin: 0;
    margin-right: 0.4rem;
    cursor: pointer;
    flex-grow: 0.5;
  }
  
  .radio_container input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 0.5rem;
    height: 1.5rem;
    box-shadow: -500px 0 0 500px var(--this-light-color);
    background: rgba(0, 0, 0, 1);
    opacity: 1;
  }
  
  input[type="range"]:hover::-webkit-slider-thumb {
    opacity: 1;
    box-shadow: -500px 0 0 500px var(--this-color);
  }
  
  
  .radio_container p:not(.immediate_ret), .radio_container input[type="text"] {
    color: rgba(0, 0, 0, 1);
    border: 2px solid rgba(0, 0, 0, 1);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 4px;
    font-weight: 600;
    text-align: center;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(1.5rem - 4px);
    margin: 0;
    padding: 0;
    transition: transform 0.2s linear;
    width: 20%;
  }
  
  .radio_container input[type="text"]:focus {
    transform: scale(1.07);
    outline: none;
  }

  .main_container h2 {
    margin-right: 0;
    margin-bottom: 0px;
    width: 15%;
    text-align: center;
    font-size: 1rem;
    flex-grow: 0.5
  }

  .max_min_labels {
    width: 100px;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: -15px;
  }
  .max_min_labels .max {
    position: absolute;
    right: 10px;
    width: 20px;
    text-align: center;
    padding-top: 5px;
    font-weight: bold
  }

  .max_min_labels .min {
    position: absolute;
    left: -5px;
    width: 20px;
    text-align: center;
    padding-top: 5px;
    font-weight: bold
  }

.main_container {
    position: relative;    
}

.main_container button {
  height: 1.5rem;
  border: 2px solid black;
  font-size: 1rem;
  padding: 0px;
  border-radius: 4px;
  width: 20%;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  transition: all 0.2s linear;
  margin-left: 3px
}

.main_container button:hover {
  background: var(--this-color);
  transform: scale(1.05);
  cursor: pointer
}



.top_banner {
    height: 4px;
    background: var(--this-color);
    position: absolute;
    top: 0;
    width: 120%;
    left: -7px
}