.main_container {
  border-radius: 6px;
  border: 1.5px solid rgb(0, 0, 0);
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.5s linear;
  width: calc(60% - 66px); /* 30px for 2x horizontal padding, plus 3px for 2 x 1.5px border*/
  margin-right: 1%;
}

.main_container.invisible_container {
  width: 0;
  overflow: hidden;
  margin: 0;
  padding: 0;
  opacity: 0;
  margin-right: 0 !important
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 0 !important;
}

.help_button {
  display: block;
  border: 2px solid rgba(0, 0, 0, 1);
  color: rgba(0, 0, 0, 1);
  border-radius: 6px;
  background-color: transparent;
  font-size: 0.8rem;
  font-weight: 600;
  height: fit-content;
}

.help_button:hover {
  cursor: pointer;
  border: 2px solid hsl(0, 0%, 0%, 0.6);
  color: hsl(0, 0%, 0%, 0.6);
}

.radio_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.radio_container input[type="radio"] {
  opacity: 0;
  position: fixed;
}

.radio_container1 {
  display: flex;
  justify-content: space-between;
}

.radio_container1 input[type="radio"] {
  opacity: 0;
  position: fixed;
}

.radio_container label {
  display: inline-block;
  padding: 0.25rem;
  color: hsl(0, 0%, 66%);
  border: 2px solid hsl(0, 0%, 66%);
  background-color: hsla(0, 0%, 66%, 0.2);
  border-radius: 4px;
  font-weight: 600;
  flex: 1;
  text-align: center;
  font-size: 1.2rem;
  margin-right: 5px;
  margin-left: 5px;
}

.radio_container label:not(:nth-of-type(1)) {
  margin-left: -0.1rem;
}

.radio_container1 label:not(:nth-of-type(1)) {
  margin-left: 0.4rem;
}

.radio_container1 label {
  display: inline-block;
  padding: 0.25rem;
  color: hsl(0, 0%, 66%);
  border: 2px solid hsl(0, 0%, 66%);
  background-color: hsla(0, 0%, 66%, 0.2);
  border-radius: 4px;
  font-weight: 600;
  flex: 1;
  text-align: center;
  font-size: 1.2rem;
}

.radio_container input[type="radio"]:checked + label {
  background-color: hsla(174, 75%, 36%, 0.2);
  border: 2px solid hsl(174, 75%, 36%);
  color: hsl(174, 75%, 36%);
}

.radio_container label:hover {
  color: hsl(205, 58%, 59%);
  border-color: hsl(205, 58%, 59%);
  text-decoration: none;
  background-color: hsla(205, 58%, 59%, 0.2);
}


.radio_container input[type="radio"]:not(:checked) + label:hover {
  cursor: pointer;
}

.radio_container input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 70%;
  height: 2.07rem;
  border: 2px solid black;
  overflow: hidden; 
  border-radius: 4px;
  outline: none;
  margin: 0;
  margin-right: 0.4rem;
  cursor: pointer;
}

.radio_container input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 0.5rem;
  height: 2.1rem;
  box-shadow: -500px 0 0 500px hsla(174, 75%, 36%, 0.5);
  background: rgba(0, 0, 0, 1);
  opacity: 1;
}

input[type="range"]:hover::-webkit-slider-thumb {
  opacity: 1;
}


.radio_container p:not(.immediate_ret), .radio_container input[type="text"] {
  display: inline-block;
  color: rgba(0, 0, 0, 1);
  border: 2px solid rgba(0, 0, 0, 1);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 4px;
  font-weight: 600;
  flex: 3;
  text-align: center;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.07rem;
  margin: 0;
  width: 20%;
  padding: 0;
  transition: transform 0.2s linear
}

.radio_container input[type="text"]:focus {
  transform: scale(1.07);
  outline: none;
}

.radio_container1 input[type="radio"]:checked + label {
  background-color: hsla(174, 75%, 36%, 0.2);
  border: 2px solid hsl(174, 75%, 36%);
  color: hsl(174, 75%, 36%);
}

.radio_container1 label:hover {
  color: hsl(205, 58%, 59%);
  border-color: hsl(205, 58%, 59%);
  text-decoration: none;
  background-color: hsla(205, 58%, 59%, 0.2);
}

.radio_container1 input[type="radio"]:not(:checked) + label:hover {
  cursor: pointer;
}

.radio_container1 input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 70%;
  height: 2.07rem;
  border: 2px solid black;
  overflow: hidden;
  border-radius: 4px;
  outline: none;
  margin: 0;
  margin-right: 0.4rem;
  cursor: pointer;
}

.radio_container1 input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 0.5rem;
  height: 2.1rem;
  box-shadow: -500px 0 0 500px hsl(205, 58%, 59%);

  background: rgba(0, 0, 0, 1);
  opacity: 0.65;
}

input[type="range"]:hover::-webkit-slider-thumb {
  opacity: 1;
}

.radio_container1 p:not(.immediate_ret) {
  display: inline-block;
  padding: 0.25rem;
  color: rgba(0, 0, 0, 1);
  border: 2px solid rgba(0, 0, 0, 1);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 4px;
  font-weight: 600;
  flex: 1;
  text-align: center;
  font-size: 1.2rem;
}


.ret_age_button, .adjust_button {
  display: inline-block;
  padding: 0.25rem;
  color: hsl(0, 0%, 66%);
  border: 2px solid hsl(0, 0%, 66%);
  background-color: hsla(0, 0%, 66%, 0.2);
  border-radius: 4px;
  font-weight: 600;
  flex: 1 1;
  text-align: center;
  font-size: 1.2rem;
  margin-right: 5px;
  margin-left: 5px;
}

.ret_age_button:hover, .adjust_button:hover {
  color: hsl(205, 58%, 59%);
    border-color: hsl(205, 58%, 59%);
    text-decoration: none;
    background-color: hsla(205, 58%, 59%, 0.2);
    cursor: pointer;
}

.adjust_button.greyed_out:hover {
  cursor: not-allowed;

}

.ret_age_button.selected {
  background-color: hsla(174, 75%, 36%, 0.2);
  border: 2px solid hsl(174, 75%, 36%);
  color: hsl(174, 75%, 36%);
}

.adjust_button.active_button {
  background-color: hsla(174, 75%, 36%, 0.2);
  border: 2px solid hsl(174, 75%, 36%);
  color: hsl(174, 75%, 36%);
}


.main_container.els {
  justify-content: flex-start;
  position: relative;
}

.els_inner_container {
      height: fit-container;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 0;
}

.radio_container.els_slider {
  position: absolute;
  top: calc(var(--height) + 7vh);
  right: 0px;
  width: calc(100% - 30px);
  transition: opacity 0.6s ease-in;
  margin-top: 15px;
  margin-right: 15px;
}

.radio_container.els_slider.invisible {
  opacity: 0;
  pointer-events: none
}

.commutation_explainer_text {
  position: absolute;
  top: calc(var(--height) + 3.5vh);
  opacity: 1;
  transition: all 0.6s ease-in;
  width: calc(100% - 30px); /* double the padding is 30px*/
  margin-right: 0;
  text-align: center;
}

.commutation_explainer_text.invisible {
  opacity: 0;
}

.commutation_explainer_text::after {
  content: '';
  width: 60%;
  border-top: 1.5px solid black;
  opacity: 1;
  position: absolute;
  left: 20%;
  top: -1vh;
}

.main_container button, .main_container h3 {
  font-size: 1.2rem !important
}


.npa_buttons_container {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 10px;
}

.warning_message {
    color: black;
    position: absolute;
    border: 2px solid black;
    background: crimson;
    top: 5%;
    left: calc(25% - 22px);
    width: 50%;
    padding: 20px;
    border-radius: 4px;
    animation: appear 0.3s ease-in
}

@keyframes appear {
  0% {opacity: 0}
  100% {opacity: 100%}
}


.main_container {
  position: relative
}

.immediate_ret {
  color: black;
  position: absolute;
  top: 115%
  
}

.extra_container {
  position: relative
}

.ret_age_container {
  margin-right: 1%
}

.reset_container {
  width:100%;
  display:flex;
  justify-content: center
}

.mini_slider_container {
  display: grid;
  grid-template-columns:  1fr 1fr; /* First column 30% and two other columns share the rest */
  grid-template-rows: 1fr 1fr;    /* Three rows of equal size */
  gap: 30px;   
  margin-top: 20px;
  min-height: 150px;
  margin-bottom: 20px;
}

.mini_slider_container h2 {
  text-align:center;
  display: flex;
  align-items:center;
  margin:0;
  justify-content: center;
}